
import {
  defineComponent,
  onMounted,
  onUpdated,
  computed,
  onBeforeMount,
  ref,
  watch
} from "vue";
import { useI18n } from "vue-i18n";
import { DrawerComponent } from "@/assets/ts/components/_DrawerOptions";
import { ToggleComponent } from "@/assets/ts/components/_ToggleComponent";
import KTMenu from "@/layout/aside/Menu.vue";
import {
  asideTheme,
  themeLightLogo,
  themeDarkLogo
} from "@/core/helpers/config";
import { useStore } from "vuex";
import { useHasRole } from "@/core/helpers/common-helper";
import APP_INFO from "@/core/constants";
import debounce from "debounce";

const getCSSVar = (varName: string) => {
  return getComputedStyle(document.documentElement).getPropertyValue(varName);
};

// refs:
// - https://davidwalsh.name/css-variables-javascript
// - https://cssgradient.io/

const ASIDE_THEMES = {
  mainApp: {
    asideScroll: "rgba(255,255,255,0.5)",
    asideBackground: "rgb(130,34,195);",
    asideBackgroundGradient:
      "linear-gradient(156deg, rgba(130,34,195,1) 0%, rgba(38,189,163,1) 100%);",
    asideLogoBackground: "rgb(130,34,195);",

    asideMenuDefaultBackground: "transparant",
    asideMenuDefaultTitle: "rgba(255,255,255,0.8)",
    asideMenuDefaultIcon: "rgba(255,255,255,0.6)",

    asideMenuHoverBackground: "rgba(255,255,255,0.1)",
    asideMenuHoverTitle: "#FFF",
    asideMenuHoverIcon: "#FFF",

    asideMenuActiveBackground: "rgba(255,255,255,0.2)",
    asideMenuActiveTitle: "#FFF",
    asideMenuActiveIcon: "#FFF"
  },
  subApp: {
    asideScroll: "rgba(255,255,255,0.5)",
    asideBackground: "#2c4771",
    asideBackgroundGradient: "#2c4771",
    asideLogoBackground: "#2c4771",

    asideMenuDefaultBackground: "#2c4771",
    asideMenuDefaultTitle: getCSSVar("--bs-light"),
    asideMenuDefaultIcon: "rgba(255,255,255,0.3)",

    asideMenuHoverBackground: "#203761",
    asideMenuHoverTitle: getCSSVar("--bs-light"),
    asideMenuHoverIcon: "#e1e787",

    asideMenuActiveBackground: "#203761",
    asideMenuActiveTitle: getCSSVar("--bs-light"),
    asideMenuActiveIcon: "#e1e787"
  },
  default: {
    asideScroll: getCSSVar("--bs-active-primary"),
    asideBackground: getCSSVar("--bs-light"),
    asideBackgroundGradient: getCSSVar("--bs-light"),
    asideLogoBackground: getCSSVar("--bs-light"),

    asideMenuDefaultBackground: "transparent",
    asideMenuDefaultTitle: getCSSVar("--bs-gray"),
    asideMenuDefaultIcon: getCSSVar("--bs-primary"),

    asideMenuHoverBackground: getCSSVar("--bs-light-primary"),
    asideMenuHoverTitle: getCSSVar("--bs-active-primary"),
    asideMenuHoverIcon: getCSSVar("--bs-active-primary"),

    asideMenuActiveBackground: "#d7f0ff",
    asideMenuActiveTitle: getCSSVar("--bs-active-primary"),
    asideMenuActiveIcon: getCSSVar("--bs-active-primary")
  },
  student: {
    asideScroll: getCSSVar("--bs-active-primary"),
    asideBackground: getCSSVar("--bs-light"),
    asideBackgroundGradient: getCSSVar("--bs-light"),
    asideLogoBackground: getCSSVar("--bs-light"),

    asideMenuDefaultBackground: "transparent",
    asideMenuDefaultTitle: getCSSVar("--bs-gray"),
    asideMenuDefaultIcon: getCSSVar("--bs-primary"),

    asideMenuHoverBackground: getCSSVar("--bs-light-primary"),
    asideMenuHoverTitle: getCSSVar("--bs-active-primary"),
    asideMenuHoverIcon: getCSSVar("--bs-active-primary"),

    asideMenuActiveBackground: "#d7f0ff",
    asideMenuActiveTitle: getCSSVar("--bs-active-primary"),
    asideMenuActiveIcon: getCSSVar("--bs-active-primary")
  },
  teacher: {
    asideScroll: getCSSVar("--bs-active-success"),
    asideBackground: getCSSVar("--bs-light"),
    asideBackgroundGradient: getCSSVar("--bs-light"),
    asideLogoBackground: getCSSVar("--bs-light"),

    asideMenuDefaultBackground: "transparent",
    asideMenuDefaultTitle: getCSSVar("--bs-gray"),
    asideMenuDefaultIcon: getCSSVar("--bs-success"),

    asideMenuHoverBackground: getCSSVar("--bs-light-success"),
    asideMenuHoverTitle: getCSSVar("--bs-active-success"),
    asideMenuHoverIcon: getCSSVar("--bs-active-success"),

    asideMenuActiveBackground: "#c0fede",
    asideMenuActiveTitle: getCSSVar("--bs-active-success"),
    asideMenuActiveIcon: getCSSVar("--bs-active-success")
  },
  staff: {
    asideScroll: getCSSVar("--bs-active-success"),
    asideBackground: getCSSVar("--bs-light"),
    asideBackgroundGradient: getCSSVar("--bs-light"),
    asideLogoBackground: getCSSVar("--bs-light"),

    asideMenuDefaultBackground: "transparent",
    asideMenuDefaultTitle: getCSSVar("--bs-gray"),
    asideMenuDefaultIcon: getCSSVar("--bs-success"),

    asideMenuHoverBackground: getCSSVar("--bs-light-success"),
    asideMenuHoverTitle: getCSSVar("--bs-active-success"),
    asideMenuHoverIcon: getCSSVar("--bs-active-success"),

    asideMenuActiveBackground: "#c0fede",
    asideMenuActiveTitle: getCSSVar("--bs-active-success"),
    asideMenuActiveIcon: getCSSVar("--bs-active-success")
  },
  admin: {
    asideScroll: getCSSVar("--bs-warning"),
    asideBackground: getCSSVar("--bs-light"),
    asideBackgroundGradient: getCSSVar("--bs-light"),
    asideLogoBackground: getCSSVar("--bs-light"),

    asideMenuDefaultBackground: "transparent",
    asideMenuDefaultTitle: getCSSVar("--bs-gray-dark"),
    asideMenuDefaultIcon: getCSSVar("--bs-warning"),

    asideMenuHoverBackground: getCSSVar("--bs-light-warning"),
    asideMenuHoverTitle: getCSSVar("--bs-warning"),
    asideMenuHoverIcon: getCSSVar("--bs-warning"),

    asideMenuActiveBackground: "#ffeca2",
    asideMenuActiveTitle: getCSSVar("--bs-gray-dark"),
    asideMenuActiveIcon: getCSSVar("--bs-gray-dark")
  }
};

const __default__ = defineComponent({
  name: "KTAside",
  components: {
    KTMenu
  },
  props: {
    isSubApp: Boolean,
    lightLogo: String,
    darkLogo: String,
    isAdminPermission: Boolean
  },
  setup(props) {
    const { t } = useI18n();
    const store = useStore();

    const handleWindowResizing = debounce(() => {
      const rect = document
        .querySelector("#kt_aside .aside-menu")
        ?.getBoundingClientRect();
      const currentAsideHeight = window.innerHeight - (rect?.y || 0);
      const asideWrapper = document.querySelector("#kt_aside_menu_wrapper");
      (asideWrapper as any).style.height = `${currentAsideHeight}px`;
    }, 300);

    onMounted(() => {
      window.addEventListener("resize", handleWindowResizing);
    });
    onBeforeMount(() => {
      window.removeEventListener("resize", handleWindowResizing);
    });

    onMounted(() => {
      DrawerComponent.reinitialization();
      ToggleComponent.reinitialization();
    });

    onUpdated(() => {
      ToggleComponent.bootstrap();
    });

    const roleUtils = useHasRole();

    const userType = computed(() => store.getters.userType.toLowerCase());
    const themeColor = computed(() => {
      if (props.isSubApp) return ASIDE_THEMES.subApp;
      return ASIDE_THEMES.mainApp;
      // if (!userType.value) return ASIDE_THEMES.default;
      // return ASIDE_THEMES[userType.value] || ASIDE_THEMES.default;
    });

    return {
      t,
      userType,
      roleUtils,
      asideTheme,
      themeLightLogo,
      themeDarkLogo,
      themeColor,
      APP_INFO
    };
  }
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "7e683ded": (_ctx.themeColor.asideBackground),
  "bce4e310": (_ctx.themeColor.asideLogoBackground),
  "2a161891": (_ctx.themeColor.asideMenuDefaultBackground),
  "f766e5d6": (_ctx.themeColor.asideMenuDefaultTitle),
  "4e92061c": (_ctx.themeColor.asideMenuDefaultIcon),
  "c0811368": (_ctx.themeColor.asideMenuHoverBackground),
  "4468467a": (_ctx.themeColor.asideMenuHoverTitle),
  "c1d1e7d2": (_ctx.themeColor.asideMenuHoverIcon),
  "74e1e4b6": (_ctx.themeColor.bsLightPrimary),
  "2bc92252": (_ctx.themeColor.asideMenuActiveBackground),
  "2958aeb4": (_ctx.themeColor.asideMenuActiveTitle),
  "a28896c6": (_ctx.themeColor.asideMenuActiveIcon),
  "7563f1e8": (_ctx.themeColor.asideScroll)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__