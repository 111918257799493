
import { defineComponent, inject } from "vue";
import { useHasRole } from "../../core/helpers/common-helper";
import { checkPermission } from '@/router/authentication'
import { DrawerComponent } from "@/assets/ts/components/_DrawerOptions";
export default defineComponent({
  setup() {
    const currentActiveMenu = inject("SIDEBAR_ACTIVE_MENU") as any;
    const setCurrentActiveMenu = inject("SIDEBAR_SET_ACTIVE_MENU") as (
      menuItem: any
    ) => void;
    const handleClose = () => {
      setCurrentActiveMenu(null);
    };
    const beforeNavigate = navigate => {
      DrawerComponent.hideAll();
      navigate();
      handleClose();
    };
    const roleUtils = useHasRole();
    const isAccessedMenu = (menuItem: any) => {
      if (Object.prototype.hasOwnProperty.call(menuItem, "accessPermissionName")) {
        return checkPermission(menuItem.accessPermissionName).isGranted;
      }

      return true;
    };
    return {
      ...roleUtils,
      handleClose,
      currentActiveMenu,
      beforeNavigate,
      isAccessedMenu
    };
  }
});
