/**
 * ============
 * ROLE RULES
 * =========
 * Role rules:
 *
 * - `["*"]` = Open for all logged user
 * - `["-admin"]` = Open for all logged user, EXCEPT admin
 * - `["-admin", "student", "teacher"]` = THIS RULE IS INVALID BUT IT WILL STILL HANDLED IT
 * - `["student", "teacher"]` = THIS RULE IS BETTER THAN PREV RULE. Open for student and teacher only
 *
 */

export const roleRulesParser = (rules: string[]) => (userType: string) => {
  const userRole = userType.toLowerCase();
  const isForAllLoggedUser = rules.includes("*");

  const excludedPermissions = rules
    .filter((rule) => rule.includes("-"))
    .map((rule) => rule.replace("-", ""));
  const userIsExcludedInPermission = excludedPermissions.includes(userRole);
  const isHasExcludedPermissions =
    excludedPermissions && excludedPermissions.length > 0;

  const includedPermissions = isHasExcludedPermissions
    ? rules.filter((rule) => !rule.includes("-"))
    : rules;

  const itOnlyHaveExcludedPermissions = includedPermissions.length === 0;
  const userIsInPermission = itOnlyHaveExcludedPermissions
    ? true
    : includedPermissions.includes(userRole);

  const granted =
    (isForAllLoggedUser || userIsInPermission) && !userIsExcludedInPermission;
  return granted;
};