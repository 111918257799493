
import TableSkeleton from "@/components/skeleton/Table.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    TableSkeleton
  },
  setup() {
    return {};
  }
});
