
import { defineComponent, ref } from "vue";

export default defineComponent({
  setup() {
    const isProfileReady = ref(true);

    return { isProfileReady };
  }
});
