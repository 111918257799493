
import { defineComponent } from "vue";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import Swal from "sweetalert2";
import APP_INFO from "@/core/constants";
import { useRouter } from "vue-router";
import { timeAgo } from "@/core/helpers/date-helper";

export default defineComponent({
  props: {
    menuItem: Object
  },
  components: {},
  setup() {
    const router = useRouter();
    const goToAttributionPage = () => {
      router.push("/attributions");
      Swal.close();
    };

    (window as any).__MUMTAZ__goToAttributionPage = goToAttributionPage;

    // Create Version Text
    const createVersionText = () => {
      return "0.0";
    };

    // refs: https://www.freecodecamp.org/news/html-button-onclick-javascript-click-event-tutorial/
    const showVersion = () => {
      Swal.fire({
        icon: "warning",
        title: "App Info",
        html: `
        <div>
          <div>Current App Version is <b>${createVersionText()}-${
          APP_INFO.ENVIRONMENT
        }</b></div>
          <div class="mt-6">
            <a 
              class="fw-bolder text-decoration-underline" 
              onclick="__MUMTAZ__goToAttributionPage()" 
              href="#"
            >
              LICENSES
            </a>
          </div>
        </div>
        `
      });
    };
    return {
      asideMenuIcons,
      version,
      showVersion
    };
  }
});
