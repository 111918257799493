
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  props: {},
  components: {},
  setup() {
    const store = useStore();
    const isShouldShow = computed(() => store.getters.isShowNewBreadcrumbs);
    const pageTitle = computed(() => store.getters.getNewPageTitle);
    const breadcrumbs = computed(() => store.getters.getNewBreadcrumbs);
    return {
      isShouldShow,
      pageTitle,
      breadcrumbs,
    };
  },
});
