import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "page d-flex flex-row flex-column-fluid" }
const _hoisted_2 = {
  id: "kt_wrapper",
  class: "d-flex flex-column flex-row-fluid wrapper"
}
const _hoisted_3 = {
  id: "kt_content",
  class: "content d-flex flex-column flex-column-fluid"
}
const _hoisted_4 = { class: "post d-flex flex-column-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTLoader = _resolveComponent("KTLoader")
  const _component_KTAside = _resolveComponent("KTAside")
  const _component_SubAside = _resolveComponent("SubAside")
  const _component_KTHeader = _resolveComponent("KTHeader")
  const _component_KTToolbar = _resolveComponent("KTToolbar")
  const _component_NewToolbar = _resolveComponent("NewToolbar")
  const _component_page_skeleton = _resolveComponent("page-skeleton")
  const _component_router_view = _resolveComponent("router-view")
  const _component_KTFooter = _resolveComponent("KTFooter")
  const _component_KTScrollTop = _resolveComponent("KTScrollTop")
  const _component_SidebarProvider = _resolveComponent("SidebarProvider")
  const _component_auth_provider = _resolveComponent("auth-provider")

  return (_openBlock(), _createBlock(_component_auth_provider, null, {
    default: _withCtx(() => [
      _createVNode(_component_SidebarProvider, null, {
        default: _withCtx(() => [
          (_ctx.loaderEnabled)
            ? (_openBlock(), _createBlock(_component_KTLoader, {
                key: 0,
                logo: _ctx.loaderLogo
              }, null, 8, ["logo"]))
            : _createCommentVNode("", true),
          _createVNode("div", _hoisted_1, [
            (_ctx.asideEnabled)
              ? (_openBlock(), _createBlock(_component_KTAside, {
                  key: 0,
                  lightLogo: _ctx.themeLightLogo,
                  darkLogo: _ctx.themeDarkLogo,
                  isAdminMenu: _ctx.isAdminMenu,
                  isAdminPermission: _ctx.isAdminPermission,
                  isSubApp: true
                }, null, 8, ["lightLogo", "darkLogo", "isAdminMenu", "isAdminPermission"]))
              : _createCommentVNode("", true),
            _createVNode(_component_SubAside),
            _createVNode("div", _hoisted_2, [
              _createVNode(_component_KTHeader, { title: _ctx.pageTitle }, null, 8, ["title"]),
              _createVNode("div", _hoisted_3, [
                (_ctx.subheaderDisplay)
                  ? (_openBlock(), _createBlock(_component_KTToolbar, {
                      key: 0,
                      breadcrumbs: _ctx.breadcrumbs,
                      title: _ctx.pageTitle
                    }, null, 8, ["breadcrumbs", "title"]))
                  : _createCommentVNode("", true),
                (_ctx.subheaderDisplay)
                  ? (_openBlock(), _createBlock(_component_NewToolbar, { key: 1 }))
                  : _createCommentVNode("", true),
                _createVNode("div", _hoisted_4, [
                  _createVNode("div", {
                    class: {
                  'container-fluid': _ctx.contentWidthFluid,
                  container: !_ctx.contentWidthFluid
                }
                  }, [
                    (_ctx.isShowPagePlaceholder)
                      ? (_openBlock(), _createBlock(_component_page_skeleton, { key: 0 }))
                      : _createCommentVNode("", true),
                    (!_ctx.isShowPagePlaceholder)
                      ? (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
                      : _createCommentVNode("", true)
                  ], 2)
                ])
              ]),
              _createVNode(_component_KTFooter)
            ])
          ]),
          _createVNode(_component_KTScrollTop)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}