import { createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-items-stretch flex-shrink-0" }
const _hoisted_2 = {
  class: "d-flex align-items-center ms-1 ms-lg-3",
  id: "kt_header_user_menu_toggle"
}
const _hoisted_3 = {
  class: "cursor-pointer symbol symbol-30px symbol-md-40px ms-5",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-attach": "parent",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTUserMenu = _resolveComponent("KTUserMenu")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("img", {
          style: {"object-fit":"contain"},
          src: _ctx.userAvatar,
          alt: "MTI"
        }, null, 8, ["src"])
      ]),
      _createVNode(_component_KTUserMenu)
    ])
  ]))
}