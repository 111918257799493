
import { defineComponent, ref, onBeforeUnmount } from "vue";
import { convertToFullDateTimeWithMonthLabel } from "../../../core/helpers/date-helper";
export default defineComponent({
  setup() {
    const currentDate = ref(new Date());
    const interval = setInterval(() => {
      currentDate.value = new Date();
    }, 1000);
    onBeforeUnmount(() => {
      clearInterval(interval);
    });
    return { currentDate, convertToFullDateTimeWithMonthLabel };
  }
});
