
import { defineComponent, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import KTAside from "@/layout/aside/Aside.vue";
import SubAside from "@/layout/aside/SubAside.vue";
import SidebarProvider from "@/layout/aside/Provider.vue";
import KTHeader from "@/layout/header/Header.vue";
import KTFooter from "@/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/LayoutService";
import KTToolbar from "@/layout/toolbar/Toolbar.vue";
import NewToolbar from "@/layout/toolbar/NewToolbar.vue";
import KTScrollTop from "@/layout/extras/ScrollTop.vue";
import KTLoader from "@/components/Loader.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { MenuComponent } from "@/assets/ts/components/index";
import { removeModalBackdrop } from "@/core/helpers/dom";
import {
  toolbarDisplay,
  loaderEnabled,
  contentWidthFluid,
  loaderLogo,
  asideEnabled,
  subheaderDisplay,
  themeLightLogo,
  themeDarkLogo
} from "@/core/helpers/config";
import { isDocPage } from "@/core/helpers/documentation";
import AuthProvider from "@/AuthProvider.vue";
import PageSkeleton from "./PageSkeleton.vue";

export default defineComponent({
  name: "Layout",
  props: {
    isAdminMenu: Boolean
  },
  components: {
    KTAside,
    KTHeader,
    KTFooter,
    KTToolbar,
    KTScrollTop,
    KTLoader,
    SubAside,
    SidebarProvider,
    NewToolbar,
    AuthProvider,
    PageSkeleton
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isAdminPermission = store.getters.userType != "Student";
    const isShowPagePlaceholder = computed(
      () => store.getters.getIsShowPagePlaceholder
    );

    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

    const validPageTitle = computed(() => {
      return store.getters.isShowNewBreadcrumbs && store.getters.getNewPageTitle
        ? store.getters.getNewPageTitle
        : store.getters.pageTitle;
    });
    // watch(
    //   [schoolInfo, validPageTitle],
    //   ([val, pageTitleVal]) => {
    //     if ((val as any).name && pageTitleVal) {
    //       document.title = `${(val as any).name} - ${pageTitleVal}`;
    //     }
    //   },
    //   { immediate: true }
    // );

    // initialize html element classes
    HtmlClass.init();

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath;
    });

    onMounted(() => {
      // // check if current user is authenticated
      if (!store.getters.isUserAuthenticated) {
        router.push({ name: "login" });
      }

      // Simulate the delay page loading
      setTimeout(() => {
        // Remove page loader after some time
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      }, 500);
    });

    watch(
      () => route.path,
      () => {
        MenuComponent.hideDropdowns(undefined);

        // // check if current user is authenticated
        if (!store.getters.isUserAuthenticated) {
          router.push({ name: "login" });
        }

        removeModalBackdrop();
      }
    );

    return {
      toolbarDisplay,
      loaderEnabled,
      contentWidthFluid,
      loaderLogo,
      asideEnabled,
      subheaderDisplay,
      pageTitle,
      breadcrumbs,
      isDocPage,
      themeLightLogo,
      themeDarkLogo,
      isAdminPermission,
      isShowPagePlaceholder
    };
  }
});
