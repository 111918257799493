import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_skeleton_item = _resolveComponent("el-skeleton-item")
  const _component_el_skeleton = _resolveComponent("el-skeleton")
  const _component_el_col = _resolveComponent("el-col")
  const _component_el_row = _resolveComponent("el-row")
  const _component_table_skeleton = _resolveComponent("table-skeleton")
  const _component_el_card = _resolveComponent("el-card")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_row, {
      class: "justify-content-between",
      gutter: 20
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 4 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_skeleton, {
              class: "mb-4",
              style: {"width":"100%"},
              animated: ""
            }, {
              template: _withCtx(() => [
                _createVNode(_component_el_skeleton_item, {
                  variant: "text",
                  style: {"width":"100%","height":"35px"}
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 12 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_row, {
              gutter: 20,
              class: "w-100"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 8 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_skeleton, {
                      class: "mb-4",
                      style: {"width":"100%"},
                      animated: ""
                    }, {
                      template: _withCtx(() => [
                        _createVNode(_component_el_skeleton_item, {
                          variant: "text",
                          style: {"width":"100%","height":"35px"}
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 16 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_skeleton, {
                      class: "mb-4",
                      style: {"width":"100%"},
                      animated: ""
                    }, {
                      template: _withCtx(() => [
                        _createVNode(_component_el_skeleton_item, {
                          variant: "text",
                          style: {"width":"100%","height":"35px"}
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_table_skeleton, { rows: 15 })
      ]),
      _: 1
    })
  ], 64))
}