import { translate } from "../helpers/translate";

/**
 * ============
 * MENU RULES
 * =========
 * Menu userTypePermission rules:
 *
 * ["*"] = Open for all logged user
 * ["-admin"] = Open for all logged user, EXCEPT admin
 * ["-admin", "student", "teacher"] = THIS RULE IS INVALID BUT IT WILL STILL HANDLED IT
 * ["student", "teacher"] = THIS RULE IS BETTER THAN PREV RULE. Open for student and teacher only
 *
 */
const UniversalMenuConfig: object = [
  {
    pages: [
      {
        heading: translate("aside.menu.home"),
        route: "/app/dashboard",
        svgIcon: require("@/assets/media/icons/duotone/Home/Home2.svg"),
        fontIcon: "bi-app-indicator",
        userTypePermission: ["*"]
      }
    ]
  }
];
export default UniversalMenuConfig;
