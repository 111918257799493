
import { computed, defineComponent } from "vue";
import KTTopbar from "@/layout/header/Topbar.vue";
import TimeInfo from "./partials/TimeInfo.vue";

import {
  headerWidthFluid,
  headerLeft,
  asideDisplay
} from "@/core/helpers/config";
import { useStore } from "vuex";

export default defineComponent({
  name: "KTHeader",
  props: {
    title: String
  },
  components: {
    KTTopbar,
    TimeInfo
  },
  setup() {
    const store = useStore();

    // mutations
    const stateSchoolImage = computed(() => {
      return store.getters.getSchoolImage;
    });

    const appConfigData = computed(() => store.getters.getSchoolInfo);

    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay,
      stateSchoolImage,
      appConfigData
    };
  }
});
