import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column flex-root" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_loading = _resolveDirective("loading")

  return _withDirectives((_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.isProfileReady)
      ? _renderSlot(_ctx.$slots, "default", { key: 0 })
      : _createCommentVNode("", true)
  ], 512)), [
    [
      _directive_loading,
      !_ctx.isProfileReady,
      void 0,
      {
        fullscreen: true,
        lock: true
      }
    ]
  ])
}