
import { defineComponent, onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import { AppMenuConfig } from "@/core/config/MainMenuConfig";
import { roleRulesParser } from "../../core/helpers/roles";
import MenuItem from "./MenuItem.vue";
import MenuItemWithoutLink from "./MenuItemWithoutLink.vue";
import { checkPermission } from '@/router/authentication'
import { forEach } from "lodash";

export default defineComponent({
  props: {},
  components: {
    MenuItem,
    MenuItemWithoutLink
  },
  setup() {
    const store = useStore();
    const { t, te } = useI18n();
    const scrollElRef = ref<null | HTMLElement>(null);
    const userType = ref("user");

    onMounted(() => {
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = text => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    // eslint-disable-next-line
    const isPermittedMenu = (menuItem: any) => {
      const userRole = userType.value;
      const routePermissionChecker = roleRulesParser(
        menuItem.userTypePermission
      );
      const isGrantedUser = routePermissionChecker(userRole);

      return isGrantedUser;
    };

    const isAccessedMenu = (menuItem: any) => {
      if (Object.prototype.hasOwnProperty.call(menuItem, "accessPermissionName")) {
        if(menuItem.accessPermissionName instanceof Object) {
          for (let index = 0; index < menuItem.accessPermissionName.length; index++) {
            const accessPermissionName = menuItem.accessPermissionName[index];
            
            if(checkPermission(accessPermissionName).isGranted) {
              return true;
            }
          }

          return false;
        } else {
          return checkPermission(menuItem.accessPermissionName).isGranted;
        }
      }

      return true;
    };

    return {
      isPermittedMenu,

      AppMenuConfig,

      asideMenuIcons,
      version,
      translate,
      userType,
      isAccessedMenu
    };
  }
});
