
import { defineComponent, provide, ref } from "vue";
export default defineComponent({
  setup() {
    const currentActiveMenu = ref(null);
    const setCurrentActiveMenu = menuItem => {
      currentActiveMenu.value = menuItem;
    };
    provide("SIDEBAR_ACTIVE_MENU", currentActiveMenu);
    provide("SIDEBAR_SET_ACTIVE_MENU", setCurrentActiveMenu);
  }
});
