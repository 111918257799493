
import { defineComponent, inject } from "vue";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import { DrawerComponent } from "@/assets/ts/components/_DrawerOptions";

export default defineComponent({
  props: {
    menuItem: Object
  },
  components: {},
  setup() {
    const setCurrentActiveMenu = inject("SIDEBAR_SET_ACTIVE_MENU") as (
      menuItem: any
    ) => void;
    const openSubmenu = (menuItem, navigate) => {
      if (menuItem.sub) {
        return setCurrentActiveMenu(menuItem);
      }
      DrawerComponent.hideAll();
      setCurrentActiveMenu(null);
      navigate();
    };
    return {
      asideMenuIcons,
      version,
      openSubmenu
    };
  }
});
